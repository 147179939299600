//mt-user
#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog {
    background: #eef7ff63 !important;
    backdrop-filter: blur(5px) !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog:after {
    border-top-color: #eef7ff63 !important;
    top: 100.5% !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body h1 {
    color: #fafafa !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .divider {
    border-bottom: 1px solid #d6d6d6 !important;
    margin: 0.25em -0.6em !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .instructions {
    color: #fafafa !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button#unsubscribe-button {
    background: #f93b40 !important;
    color: #fafafa !important; 
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button#subscribe-button {
    background: #fcb347  !important;
    color: #fafafa !important; 
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button.action:hover {
    background: unset !important;
} 

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md {
    bottom: 100px;
    right: 20px;

    @media screen and (min-width: 991px) {
        bottom: 30px;
        right: 30px;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-message .onesignal-bell-launcher-message-body {
    font-size: 12px !important;

    @media screen and (min-width: 429px) {
        font-size: 14px !important;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-sm {
    bottom: 100px;

    @media screen and (min-width: 991px) {
        bottom: 30px;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .push-notification {
    background: #ffffff !important;
}