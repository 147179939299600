@font-face {
    font-family: "poppins";
    src: url(../../assets/fonts/Poppins-Light.ttf);
    font-display: swap;
}

@font-face {
    font-family: "poppins-italic";
    src: url(../../assets/fonts/Poppins-Italic.ttf);
    font-display: swap;
}

@font-face {
    font-family: "poppins-extrabold";
    src: url(../../assets/fonts/Poppins-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: "poppins-medium";
    src: url(../../assets/fonts/Poppins-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: "poppins-semibold";
    src: url(../../assets/fonts/Poppins-Medium.ttf);
    font-display: swap;
}

@font-face {
    font-family: "poppins-bold";
    src: url(../../assets/fonts/Poppins-SemiBold.ttf);
    font-display: swap;
}

@font-face {
    font-family: "cabin";
    src: url(../../assets/fonts/Cabin-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: "cabin-medium";
    src: url(../../assets/fonts/Cabin-Medium.ttf);
    font-display: swap;
}

@font-face {
    font-family: "cabin-semibold";
    src: url(../../assets/fonts/Cabin-SemiBold.ttf);
    font-display: swap;
}

