@import "./font";

*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html{
    background-color: $primary_background_color;
    font-size: 62.5%; //1rem == 10px (rem depend on root font size)
}

body{
    background-color: transparent !important;
    font-family: "cabin-regular", sans-serif !important;
    -webkit-touch-callout: none; /* Disable iOS callout menu */
    -webkit-user-select: none; /* Disable text selection */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}