//theme color
$primary_theme_color: #ffffff;
$secondary_theme_color: #dfdfdf;

//background color
$primary_background_color: #113B63; // dark-blue
$secondary_background_color: #006294; // light-blue

//overlay color
$overlay-backdrop: #6a6868e0;

//text color
$primary_text_color: #fdfdff;
$secondary_text_color: #F9A01B;
$tertiary_text_color: #9EBCD8;
$gray-text: #C1C1C1;

//font family
$primary_font: 'poppins';
// $secondary_font: 'Poppins';
