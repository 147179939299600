@import "abstract/variables";

@import "base/animations";
@import "base/font";
@import "base/base";

@import "components/element";

.content-container {
    height: 100%;
    min-height: 100vh;
    @media screen and (min-width: 991px) {
        padding: 1px 16px;        
    }

    // &.--snow {
    //     background-image: url('../assets/images/snow_effect_desktop.gif');
    //     // background-repeat: no-repeat;
    //     background-size: contain;
    // }
}

.main-header-container{
    background-color: #414141;
    text-align: center;
    z-index: 5;
    &.--mobile{        
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        label{
            z-index: 5;
            margin-left: 0.5em;
            font-size: 2rem;
            color: #FAFAFA;
        }
        .company-logo {
            width: 180px;
            height: 60px;
            z-index: 5;
        }
    }
}

.modal-header-container{
    background-color: #FDFDFD;
    width: 100%;
    z-index: 5;
    padding: 30px 15px 20px;
    position: fixed !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    color: #303345;
    font-size: 18px;
    font-family: 'poppins-medium';

    .info-row {
        height: 27px;
        .info-col{
            display: flex;
            justify-content: center;
            align-items: end;
        }
    }
    
    @media (min-width: 550px) {
        padding: 50px 15px 20px;
    }
    
    @media (min-width: 991px) {
        position: sticky !important;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }
}

.top-navbar {
    z-index: 100 !important;
    padding: 17px 30px;
    background: #FDFDFD; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .row {
        .col {
            min-height: 35px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            div {
                position: relative;
            }

            @media (min-width: 550px) {
                min-height: 60px;
            }
        }
    }
   
    .badge{
        position: absolute;
        top: 0;
        // right: 0;
        left: 18px;
        padding: 3px 4.5px 2px;  
        background: #F9A01B;
        border-radius: 100%; 
        font-size: 8px;
        vertical-align: middle;
        color: $primary_text_color ;
    }  
    .animation-icon{
        animation: shake 0.4s infinite;
    }

    &.--withCategory {
        padding: 10px 30px 5px;

        @media (max-width: 550px) {
            padding: 10px 15px 5px;
        }

        .row {
            .col {
                min-height: 50px;
            }
        }
    }
}

.side-navbar-container{
    margin: 0;
    padding: 0;
    width: 200px;
    background-color: #f0f0f0;
    position: fixed;
    height: 100%;
    overflow: auto;
    .logo-icon{
        width: 6em;
        height: 6em;
    }
    .row:first-child{
        margin-top: 5em;
        margin-left: 0;
        margin-right: 0;
    }
    .tab-bar{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #222A39;
        font-size: 2rem;
        margin-top: 0.5em;
        &.--active{
            width: 7em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1.7rem;
            background: #EBEBEB;
            color: #222A39;
            font-size: 2rem;
        }
        img{
            margin-right: 0.5em;
        }
        .navbar-label{
            width: 3.5em;
        }
    }
    .post-button {
        width: 140px;
        box-shadow: none !important;
        border-radius: 1.7rem !important;
        margin: 0.5em 0 0 0;
        background: #414141 !important;
    }
    .nav-icon {
        width: 25px;
        height: 25px;
    }
}

.bottom-navbar-container{
    background-color: #ffffff;
    padding: 2em 0 2em 0;
    border-top: 0.1em solid #AFACAC;
    .row{
        .col{
            display: grid;
            place-items: center;
            max-height: 60px;
        }
    }
    .nav-icon {
        height: 40px;
        width: 40px;
    }
}

.footer-container {
    z-index: 1;
    position: relative;
    background: transparent; // change footer bg
    padding: 0em 1em 2em 1em;
    width: 100%;

    .footer-logo {
        width: 100px;
        height: 80px;
    }

    .listing-item-container {
        transition: height 0.5s;
        overflow: hidden;

        &.active {
            transition: height 0.5s;
        }
    }
    .line-container {
        margin: 2em 0em;

        &.--grey-thin{
            height: 0.5px;
            width: 100%;
            background-color: #C5C5C5;
        }
    }

    @media (min-width : 991px) {
        padding: 8em 3em 2em 3em; 
    }

    ._label {
      font-size: 14px;
      font-family: $primary_font;
      color: #fafafa;

      @media (min-width: 600px) {
        color: #C5C5C5;
      }
  
      &.--company_name {
        &.--smaller {
          padding-top: 1em;
          font-size: 1.2rem;
        }
  
        @media (min-width: 991px) {
          width: auto;
        }

        @media (max-width : 991px) {
            font-size: 11px;
        }
      }
  
      &.--listing_label {
        &.active {
          color: #3db8ed;
        }
      }
  
      &.--listing_label:hover {
        color: #3db8ed;
      }
  
      &.--highlight_text {
        color: #648dff //#577DE5;
      }
    }
}

.full-modal-box-container{
    .modal-body::-webkit-scrollbar{
        display: none !important;
    }

    .modal-dialog {
        min-height: 100vh;
        
        @media (max-width: 550px) {
            &.--margin {
                margin: 0em 2em;
            }
        }
    }
    .modal-content{
        background-color: $primary_background_color;
        min-height: 100vh;
        
        &.--white{
            background-color: $primary_text_color;
        }
        
        &.--short{
            min-height: unset;
            border-radius: 1em;
            
            .modal-body {
                padding: 0;
                height: auto;
                overflow-y: unset;
            }
        }
    }
    
    .modal-body{
        padding: 0;
    }
    
    @media (min-width: 991px) {
         overflow-y: hidden !important;

        .modal-dialog{
            display: flex;
            margin: 0.5em auto;
            justify-content: center;
            min-height: 100vh;
        }
        .modal-content{
            border-radius: 2em;
            width: 55em;
            height: 98vh;
            min-height: 0;

            &.--white{
                background-color: $primary_text_color;
            }
            
            &.--short{
                height: unset;
                border-radius: 1em;
                
                .modal-body {
                    padding: 0;
                    height: auto;
                    overflow-y: unset;
                }
            }
        }
        .modal-body{
            padding: 0;
            height: 88vh;
            overflow-y: unset;
            // min-height: 87.5vh;
        }
    }
}

.full-modal-box-container::-webkit-scrollbar {
    display: none !important;
}    

.half-modal-box-container{
    z-index: 10 !important;
    position: relative;

    [data-rsbs-overlay] {
        background: $primary_background_color;
    }

    [data-rsbs-scroll]::-webkit-scrollbar{
        display: none !important;
    }

    .modal-body {
        padding: 0em 2em;
    }

    &.bank-modal {
        [data-rsbs-overlay] {
            background: #fdfdff;
        }
        
        [data-rsbs-content], [data-rsbs-header] {
            background-color: #fdfdff;
        }
    }
}

.menu-page-container {
    padding: 70px 10px 120px !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 1.5em;   
    }

    .store-header {
        padding: 0;
    }

    .store-banner {
        padding: 1.2em 1em;
        background: $primary_text_color; 
        border-radius: 14px; 
    }

    .store-announcement {
        width: 100%;
        padding: 1.2em 1em;
        background: #EEF7FF;
        border-radius: 6px; 

        position: absolute;
        z-index: 10;
    }

    ._label {
        font-family: 'poppins-bold';

        &.--store-name {
            color: #F9A01B;
            font-size: 26px;

            span {
                color: $primary_background_color;
            }
        }

        &.--change {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 0.5em 0.8em;
            background: $primary_background_color; 
            border-radius: 22px;

            color: $primary_text_color;
            font-size: 12px;
        }

        &.--store-tag {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 0.5em 0em;

            color: #83829A;
            font-size: 12px;
        }

        &.--announcement {
            width: 95%;
            color: $secondary_background_color;
            font-size: 13px;
            font-family: 'poppins-semibold';
            text-align: center;

            &.one-line {
                display: -webkit-inline-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                white-space: normal;

                text-overflow: ellipsis;
            }

            .line {
                margin: 0.8em 0em;
                border-top: 1px solid #d4e6f7;
            }

            @media (max-width: 991px) {
                width: 85%;
            }
        }
    }
}

.change-cart-container {
    padding: 2em 3em 3em !important;

    .image-frame {
        padding: 0;
        &.--change {
            width: 168px;
            height: 168px;
        }
    }

    ._label {
        color: #113B63;
        font-family: 'poppins-medium';
        
        &.--proceed-change {
            font-size: 16px;

            span {
                color: #ff2b35;
            }
        }    

        &.--proceed-change-desc {
            font-size: 14px;
            font-family: 'poppins';
        }
    }
}

.category-container{
    .category-row {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        scroll-snap-type: x mandatory;
        margin: auto;
        overflow-y: hidden;
        margin-top: 1.3em;
        margin-bottom: 0.5em;
    }
      
    .category-tab {
        display: inline-block;
        width: 35%;
        text-align: center;
        scroll-snap-align: center;

        @media (min-width: 991px) {
            width: 20%;
        }

        .image-frame {
            margin: auto;

            &.--category {
                width: 42px;
                height: 39px;
            }
        }
        
        label {
            color: white;
            font-size: 12px;
            font-family: 'poppins';

            overflow: hidden;
            white-space: nowrap; 
            text-overflow: ellipsis; 
            display: block;
            width: 100%; 
        }

        .active{
            color: $secondary_text_color;
        }
    }

    .category-row::-webkit-scrollbar{
        display: none !important;
    }

    &.--stick {
        //background: aliceblue;
        border-radius: 45px;

        @media (max-width: 550px) {
            padding: 0em 1em 0.5em;

            .category-tab {
                width: 50%;
            }
        }

        .category-tab { 
            overflow: hidden;
            text-overflow: ellipsis;

            a {
                display: flex;
                justify-content: center;
            }

            label {
                font-size: 14px;
                font-family: 'poppins-semibold';
                color: #9fa6b2;

                overflow: hidden;
                white-space: nowrap; 
                text-overflow: ellipsis; 
                display: block;
                width: 10em; 
            }
    
            .active{
                color: $secondary_text_color;
            }
        }
    }
}

.cart-container{
    padding: 0em 0em 20em !important;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    @media (min-width: 991px) {
        padding: 0em 3em 20em !important;
    }

    .image-frame{
        &.--food {
            width: 360px;
            height: 197px; 
            margin: 1em auto 2em;

            img {
                border-radius: 1.2em;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
            }
        }
    }
    ._label {
        color: $primary_text_color;
        font-family: 'poppins-semibold';
        
        &.--place-order {
            padding: 2em 0.5em 1em;
            font-size: 18px;
        }
        &.--menu-name {
            font-size: 18px;
        }
        &.--menu-desc {
            font-size: 12px;
            font-family: 'poppins';

            display: -webkit-inline-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
        }
        &.--menu-price {
            font-size: 18px;
            font-family: 'poppins-semibold';
        }
        &.--base-price {
            font-size: 14px;
            font-family: 'poppins';
        }

        &.--note {
            font-size: 16px;
            font-family: 'cabin';

            span {
                color: #C3C4CD;
                font-size: 12px;
                font-family: 'cabin';
            }
        }
    }
    // .count_button{
    //     width: 36em;
    //     margin: auto;
    //     align-items: center;
    //     justify-content: center;
    //     position: relative;
    // }
    // .button{
    //     position: relative;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }
} 

.addon-listing {
    .addon-row {
        display: flex;
        align-items: center;
        padding: 1em 1.8em;
    }
    .note-row {

    }
    ._label {
        color: $primary_text_color;
        font-family: 'poppins-semibold';
        
        &.--addon-title {
            font-size: 16px;
            font-family: 'cabin';

            span {
                color: #9EBCD8;
                font-size: 12px;
                font-family: 'cabin';
            }
        }
        &.--addon-price {
            font-size: 14px;
            color: $secondary_text_color;
            font-family: 'cabin-medium';
        }
        &.--menu-name {
            font-size: 18px;
        }
        &.--menu-desc {
            font-size: 12px;
            font-family: 'poppins';
        }
        &.--menu-price {
            font-size: 18px;
            font-family: 'poppins-semibold';
        }
        &.--base-price {
            font-size: 14px;
            font-family: 'poppins';
        }
    }
}

.menu-listing{
    margin-top: 1.1em;
    margin-bottom: 2em;
    background: rgba(0, 0, 0, 0);

    &.--food {
        min-height: 20em;
    }

    .menu-listing-container{
        -webkit-animation: slide-top 15s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 15s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        width: 142px;
        height: 142px;
        background: #0D5E8D;
        border-radius: 18px;
        box-shadow: 0px 10px 40px rgba(100.94, 100.94, 100.94, 0.20);

    }
    
    .image-row {
        .image-frame{
            position: relative;
            &.--food{
                margin-top: 1.6em;
                width: 126px;
                height: 126px;
                padding: 0;
                
                img {
                    // top: 1.8em;
                    // position: absolute;
                    border-radius: 12px;
                    filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.25));
                }
                
                &.out-stock {
                    opacity: 0.4;
                }
            }
        }

        span {
            top: 55%;
            position: absolute;
            font-size: 12px; 
            color: $primary_text_color;
            text-transform: uppercase;
            transform: translateY(-50%);
        }
    }

    .food-info {
        text-align: center;
        margin-top: 2em;
        padding: 0;

        &.--out-stock {
            opacity: 0.4;
        }
    }

    ._label{
        font-family: 'poppins-bold';
        color: $primary_text_color;

        &.--category-name {
            font-size: 18px;
        }
        &.--food{
            font-size: 14px;
            font-family: 'poppins';

            .quoted-text {
                color: $secondary_text_color;
            }
        }
    }

}

.checkout-page-container {
    padding: 70px 10px 150px !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 1.5em;   
    }

    ._label {
        color: $primary_text_color;
        font-family: 'poppins';

        &.--confirm-order {
            display: flex;
            align-items: center;

            color: #F9A01B;
            font-size: 30px;
            font-family: 'cabin-semibold';
        }    

        &.--order-summary {
            font-size: 14px;
            font-family: 'cabin-medium';
        }

        &.--tag {
            display: flex;
            align-items: center;
            gap: 8px;

            padding: 0.2em 0.8em;
            font-size: 16px;
            font-family: 'cabin';
            background: #305676;
            border-radius: 20px;
        }
    }
}

.checkout-form-container {
    .special-remark {
        border-radius: 11px; 
        border: 1px #305676 solid;

        div {
            color: $tertiary_text_color;
            font-size: 12px;
            font-family: 'poppins';
        }
    }

    .image-frame {
        &.--rider {
            width: 130px;
            height: 100px;

            @media (max-width: 550px) {
                width: 120px;
                height: 100px;
            }
        }

        &.--empty {
            margin-top: 2em;
            margin-bottom: -1em;
            width: 100px;
            height: 100px;
            // background-color: aliceblue;

            @media (max-width: 550px) {
                margin-top: 0em;
                width: 90px;
                height: 90px;
            }
        }
    }

    .rider-type {
        padding-top: 10px; 
        padding-bottom: 10px; 
        border-radius: 11px; 
        background: #909497; 
        border: 2px #305676 solid;
        
        &.--active {
            background: #FDFDFF; 
            border: 3px #F9A01B solid;
        }
    }

    ._label {
        color: $primary_text_color;
        font-family: 'poppins';

        &.--subtitle {
            color: $secondary_text_color;
            font-size: 14px;
            font-family: 'cabin-medium';
        }    

        &.--address {
            color: $tertiary_text_color;
            font-size: 12px;
        }

        &.--edit {
            color: $secondary_text_color;
            font-size: 12px;
            font-family: 'poppins-semibold';
            text-decoration: underline;
            text-transform: uppercase;
        }

        &.--menu-info {
            font-size: 14px;


        }

        &.--menu-addon {
            color: $tertiary_text_color;
            font-size: 12px;
        }

        &.--price-label {
            font-size: 12px;
        }

        &.--price-data {
            font-size: 14px;
        }

        &.--rider-desc {
            color: #113B63;
            font-size: 13px;
            font-family: 'poppins-medium';
            
            @media (max-width: 550px) {
                font-size: 10px;
            }
        }   

        &.--add-something {
            font-family: 'poppins-medium';
            font-size: 13px;
            color: $tertiary_text_color;
            height: 10em;

            @media (max-width: 550px) {
                font-size: 12px;
            }
        }  

        &.--limited-span {
            margin-right: 2.5em;
            padding: 0.2em 0.8em;
            font-family: 'poppins-medium';
            border-radius: 1em;
            font-size: 12px;
            color: #F9A01B;
            background: #f9a01b45;
        }
    }
}

.rider-contact-container {
    padding: 0em 2em 5em !important;
    .image-frame {
        padding: 0;
        &.--logo {
            width: 235px;
            height: 62px;
        }
    }

    .provide-row {
        padding: 3em 1em;
    }

    ._label {
        color: $primary_text_color;
        font-family: 'poppins';

        &.--rider-contact-desc {
            font-size: 16px;
        }    

        &.--address {
            color: $tertiary_text_color;
            font-size: 12px;
        }

        &.--edit {
            color: $secondary_text_color;
            font-size: 12px;
            font-family: 'poppins-semibold';
            text-decoration: underline;
            text-transform: uppercase;
        }

        &.--menu-info {
            font-size: 14px;
        }

        &.--menu-addon {
            color: $tertiary_text_color;
            font-size: 12px;
        }

        &.--price-label {
            font-size: 12px;
        }

        &.--price-data {
            font-size: 14px;
        }

        &.--estimated-time {
            font-size: 16px;
            font-family: 'cabin';
        }

    }

    @media (min-width: 991px) {
        padding: 0em 7em 5em !important;
    }
}

.reorder-container {

    .quantity-circle {
        padding: 2px 12px;
        border-radius: 50px;
        border: 1px $secondary_text_color solid;

        color: $secondary_text_color;
        font-size: 16px;
        font-family: 'poppins-bold';
    }

    ._label {
        color: $primary_text_color;
        
        &.--reorder-label {
            font-size: 22px;
            font-family: 'poppins-medium';
        }

        &.--reorder-info {
            font-size: 14px;
            font-family: 'poppins';
        }

        &.--reorder-addon {
            font-size: 12px;
            font-family: 'poppins';
        }

        &.--total-price{
            color: #C6C6C6;
            font-size: 14px;
            font-family: 'poppins-medium';
        }
    }
}

.select-bank {
    color: $primary_background_color;
    font-size: 20px;
    font-family: 'cabin-semibold';
}

.bank-container {
    @media (max-width: 991px) {
        padding-bottom: 3em;
    }

    .image-frame {
        &.--bank {
            width: 4em;
            height: 2em;

            img {
                object-fit: contain;
            }
        }
    }

    .bank-tab {
        margin-top: 1em;
        padding: 0.6em 1em;
        border-radius: 10px; 
        // border: 2px #305676 solid;

        color: $primary_background_color;
        font-family: 'poppins-medium';
        font-size: 16px;

        &.--active {
            color: $secondary_text_color;
            
            .bank-circle {
                svg {
                    color: $secondary_text_color;
                }
            }
        }

        @media (max-width: 550px) {
            padding: 0.6em 0em; 
        }
    }

    .bank-circle {
        padding: 0.5em;
        background: #006294;
        box-shadow: 0 4px 5px #2b2b2b54;
        border-radius: 60px;

        svg {
            color: $primary_text_color;
        }
    }
}

.receipt-container {  
    max-width: none !important; 
    background-color: $primary_text_color;
    padding: 70px 30px 80px !important;
    box-shadow: 0 1px 10px #ebebeb !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 2em;   
    }

    @media (min-width: 991px) {
        padding: 20px 30px 80px !important;
        margin-top: 0em;
        box-shadow: unset !important;
    }

    text-align: center;

    .payment-info-container{
        padding: 1.5em 2em;
        background: #305676;
        border-radius: 10px;

        ._label {
            color: $primary_text_color;
            font-size: 14px;   
            
            &.--payment-label {
                font-family: 'poppins-medium';
            }

            &.--payment-info {
                font-family: 'poppins';
            }
        }
    }

    .dotted-line {
        border-bottom: 1px $tertiary_text_color dotted;
    }

    ._label {
        color: #878585;
        font-family: 'poppins';    

        &.--outlet-name {
            font-size: 20px;
            font-family: 'poppins-medium';      
        }

        &.--outlet-info {
            font-size: 12px;
        }

        &.--invoice {
            font-size: 16px;
        }

        &.--invoice-info {
            text-align: left;
            font-size: 14px;
        }

        &.--menu-info {
            color: #58636D;
            font-size: 14px;
            font-family: 'poppins-medium';
        }

        &.--menu-details {
            color: #878585;
            font-size: 12px;
            font-family: 'poppins';
        }

        &.--total-paid {
            font-size: 16px;
            font-family: 'poppins-medium';
        }

        &.--payment-info {
            margin: 3em 0em 0.5em;
            color: #006294;
            font-size: 20px;
            font-family: 'poppins-semibold';
        }
    }
}

.order-page-container {
    padding: 70px 10px 20px;
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 1.5em;   
    }

    .total-paid {
        border-top: 1px #F7E8DB solid;
    }

    .image-frame {
        &.--order-food {
            width: 65px;
            height: 65px;

            img {
                border-radius: 12px
            }
        }
    }

    .quantity-circle {
        padding: 7px 10px;
        border-radius: 50px; 
        border: 1px #FDFDFF solid;

        color: $primary_text_color;
        font-size: 12px;
        font-family: 'poppins';
        white-space: pre;
    }

    .steps-container {
        padding: 3em;
        position: relative;
    }

    .RSPBprogressBar {
        height: 6px;
        background: $secondary_background_color;
    }

    .steps-bar {
        background: $secondary_background_color; //305676
        width: 100%;
        height: 6px;
        border-radius: 22px;
        position: relative;
        display: flex;
        align-items: center;
    }

    .steps-circle {
        width: 24px;
        height: 24px;
        position: absolute;
        background: $secondary_background_color; 
        border-radius: 22px;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            margin-top: 0.09em;
        }
    }

    // .steps-desc-row {
    //     padding: 2em 0em;
    //     position: relative;
    // }

    .steps-desc {
        bottom: -10%;
        position: absolute;
        color: $primary_text_color;
        font-family: 'poppins';
        font-size: 14px;
        padding: 0em 1em;

        &.--first {
            left: -1%;
        }

        &.--second {
           left: 50%;
           transform: translateX(-50%);
        }

        &.--third {
            right: -1%;

            @media (max-width: 550px) {
                right: -2%;
            }
        }
    }

    ._label {
        color: $primary_text_color;
        font-family: 'poppins';
        
        &.--order-label {
            font-size: 14px;

            span {
                color: #ffac2f;
                font-family: 'poppins-medium';
            }
        }

        &.--order-data {
            font-size: 14px;
            
            display: -webkit-inline-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
        }

        &.--order-no {
            font-size: 18px;
            font-family: 'poppins-semibold';
            color: $secondary_text_color;

            // &:hover {
            // }
        }

        &.--paid-label {
            font-size: 16px;
            font-family: 'poppins-medium';
        }

        &.--paid-data {
            font-size: 16px;
            font-family: 'poppins-medium';
        }

        &.--order-food {
            font-size: 18px;
            font-family: 'poppins-medium';
        }

        &.--order-addon {
            font-size: 12px;
            color: $tertiary_text_color;
        }
    }
}

.share-earn-container {
    max-width: none !important; 
    // background-color: $primary_bg_color;
    padding: 70px 30px 80px !important;
    box-shadow: 0 1px 10px #ebebeb !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 2em;   
    }

    @media (min-width: 991px) {
        padding: 20px 30px 80px !important;
        margin-top: 0em;
        box-shadow: unset !important;
    }

    text-align: center;

    .share-earn-info-container {
        padding: 3.5em 3em;
        background: #113B63; 
        box-shadow: 8px 10px 27px rgba(39, 39, 38, 0.10); 
        border-radius: 26px; 
        border: 1px #F9A01B solid; 

        .copy-circle {
            padding: 1em;
            background: #006294; 
            border-radius: 16px; 
            backdrop-filter: blur(20px);
        }

        .withdraw-button {
            button { 
                font-size: 13px;
                font-family: 'poppins-medium';
                border-radius: 0.5em;
                padding: 0.6em 1em;
            }
        }

        ._label {
            color: $primary_text_color;   
            font-family: 'poppins';

            &.--share-earn-label {
                color: $secondary_text_color;
                font-size: 14px;
                font-family: 'poppins-medium';

            }
            
            &.--share-earn-info {
                font-size: 14px;

                .bigger {
                    font-size: 20px;
                }
            }

            &.--purchase-code {
                font-size: 20px;
                font-family: 'poppins-extrabold';

                background: linear-gradient(to left, rgba(237, 235, 235, 1), rgba(237, 235, 235, 0));
                color: transparent;
                background-clip: text;
                -webkit-background-clip: text; 

                overflow: hidden;
                white-space: nowrap; 
                text-overflow: ellipsis; 
                display: block;

                @media (max-width: 550px) {
                    font-size: 18px;
                }
            }

            &.--share-earn-tc {
                font-size: 12px;
                // font-family: 'poppins-medium';

                span {
                    color: $secondary_text_color;
                }
            }
        }
    }

    .complete-koc {
        padding: 0.5em 1em;
        border-radius: 8px; 
        border: 1px #878585 solid; 
        backdrop-filter: blur(20px);

        color: #878585;
        font-size: 16px;
        font-family: 'poppins-medium';
    }

    ._label {
        color: #878585;   
        font-family: 'poppins';
        
        &.--share-earn-desc {
            font-size: 12px;
            line-height: 24px;
        }

        &.--payment-info {
            font-family: 'poppins';
        }

        &.--details {
            font-size: 12px;
        }
    }
}

.withdraw-profit-container {
    max-width: none !important; 
    padding: 70px 30px 80px !important;
    box-shadow: 0 1px 10px #ebebeb !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 2em;   
    }

    @media (min-width: 991px) {
        padding: 20px 30px 80px !important;
        margin-top: 0em;
        box-shadow: unset !important;
    }

    text-align: center;

    .big-margin {
        margin-top: 1em;

        &.--withdraw {
            margin-top: 5em;

            @media (max-width: 550px) {
            }
        }

        @media (max-width: 550px) {
            margin-top: 1.5em;
        }
    }

    .history-line {
        margin-top: 1.6em;
        border-top: 2px solid #ebebeb;
    }

    .withdraw-to {
        color: #6c757d;
        padding: 0em 0.8em;
        font-size: 16px;
        font-family: 'poppins-medium';
        
        span {
            font-size: 14px;
            font-family: 'poppins-medium';
        }
    }

    .amount-grid {
        display: grid;
        grid-template-columns: 46% 54%;
    }

    .payout-row {
        padding: 0.8em 0em;
        border-bottom: 0.5px #3056765e solid;
    
        ._label {
            color: #878585;   
            font-family: 'poppins';
    
            &.--payout-label {
                font-size: 14px;
                font-family: 'poppins-medium';
                line-height: 16.80px;

                @media (max-width: 550px) {
                    font-size: 13px;
                }
            }
    
            &.--payout-desc {
                color: #7C7C7C;
                font-size: 12px;
                font-family: 'poppins';
                line-height: 14.40px;
            }

            &.--payout-status {
                font-size: 12px;
                font-family: 'poppins-semibold';
                line-height: 16.80px;
                color: #919191;
                background: #91919126;
                padding: 0.3em 0.8em;
                margin-left: 1em;
                border-radius: 1em;

                @media (max-width: 550px) {
                    font-size: 10px;
                }

                &.--rejected {
                    background-color: #f5303026;
                    color: #f53030;
                }

                &.--approved {
                    background-color: #24b77126;
                    color: #24b771;
                }

                @media (max-width: 380px) {
                    margin-left: 0.5em;
                }
            }
    
            &.--payout-price {
                text-align: end;
                color: $primary_background_color;
                font-size: 14px;
                font-family: 'poppins-semibold';
                line-height: 16.80px;
            }
        }

        &.--no-line {
            border-bottom: 0;
        }
    }

    ._label {
        color: #878585;   
        font-family: 'poppins';

        &.--payout-history {
            color: $primary_background_color;
            font-size: 20px;
            font-family: 'poppins-semibold';
        }
        
        &.--share-earn-desc {
            font-size: 12px;
            line-height: 24px;
        }

        &.--withdraw-amount {
            font-size: 14px;
            font-family: 'poppins-medium';
            
            span {
                font-size: 38px;
                font-family: 'poppins-semibold';
                color: $primary_background_color;
            }
        }

        &.--available-withdraw {
            font-size: 20px;
            font-family: 'poppins-semibold';
            
            span {
                font-size: 14px;
                font-family: 'poppins-medium';
            }
        }

        &.--bank-name {
            font-size: 16px;
            font-family: 'poppins-semibold';  
            color: $primary_background_color;
        }

        &.--bank-no {
            font-size: 14px;
            font-family: 'poppins-medium';  
        }

        &.--details {
            font-size: 12px;
        }
    }
}


.purchase-history-container {
    max-width: none !important; 
    padding: 0 !important;
    // background-color: $primary_bg_color;
    box-shadow: 0 1px 10px #ebebeb !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 2em;   
    }

    @media (min-width: 991px) {
        margin-top: 0em;
        box-shadow: unset !important;
    }

    .purchase-row {
        border-bottom: 0.5px #afb7bd solid;

        &.--no-line {
            border-bottom: 0;
        }
    }
    
    ._label {
        color: #878585;   
        font-family: 'poppins';

        &.--purchase-history {
            color: $primary_background_color;
            font-size: 20px;
            font-family: 'poppins-semibold';
        }

        &.--details {
            font-size: 12px;
        }

        &.--purchase-label {
            font-size: 14px;
            font-family: 'poppins-medium';
            line-height: 16.80px;
            color: $primary_background_color;
        }

        &.--purchase-desc {
            color: #7C7C7C;
            font-size: 12px;
            // font-family: 'poppins-light';
            line-height: 14.40px;
        }

        &.--purchase-price {
            color: $primary_background_color;
            font-size: 14px;
            font-family: 'poppins-semibold';
            line-height: 16.80px;
        }
    }
}

.edit-koc-container {
    max-width: none !important; 
    // background-color: $primary_bg_color;
    padding: 70px 30px 80px !important;
    box-shadow: 0 1px 10px #ebebeb !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 2em;   
    }

    @media (min-width: 991px) {
        padding: 20px 30px 80px !important;
        margin-top: 0em;
        box-shadow: unset !important;
    }

    ._label {
        color: $primary_text_color;   
        font-family: 'poppins';
        
        &.--edit-koc-desc {
            font-size: 14px;
        }
    }

    .gender-container {
        top: 100%;
        z-index: 9;
        position: absolute;
        background: $primary_text_color;
        border-radius: 1em;
        width: 100%;
        transform: translateY(-50%);
        border: 0.1em solid $primary_background_color;
        overflow: hidden;

        .row {
            padding: 1em;

            label {
                color: #5C5C5C;
                font-size: 12px;
                font-family: 'poppins-medium';
            }

            &:hover {
                background: #f1f1f1;
            }
        }

    }
}

// .body-container {
//     background-image: url('../assets/images/sample/new-year-graphic2.jpg');
//     background-size: cover;
//     background-position: center;
// }

.store-page-container {
    padding-bottom: 2em;

    .store-content {
        padding: 0em 3em 3em;

        @media (max-width: 550px) {
            padding: 0em 1em 3em;
        }
    }

    .image-frame {
        width: 288px;
        height: 100%;

        &.--map {
            width: 140px;
            height: 70px;

            img {
                border-radius: 5px
            }
        }

        &.--store {
            width: unset;
            height: unset;
            
            padding: 0em 0.8em;
            
            img {
                min-height: 138px;
                min-height: 120px;
                box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.10);
                border-radius: 10px; 
            }
        }

        &.--toggle {
            width: 15em;
            height: 14em;

            @media (max-width: 550px) {
                width: 12em;
                height: 11em;
            }
        }

        @media (min-width: 991px) {
            width: 400px;
        }
    }
    .option-toggle {
        display: flex;
        justify-content: center;
        gap: 40px;
        padding: 12px 30px;
        margin-top: 4em;
        z-index: 5;

        .toggle-tab {
            background: #1a4369;
            width: 20em;
            height: auto;
            // display: flex;
            // justify-content: center;
            // align-content: end;
            text-align: center;
            position: relative;
            flex-wrap: wrap;
            border-radius: 0.8em;
            box-shadow: 2px 6px 15px #17324d;
            padding-bottom: 0.6em;

            &.--active {
                border: 1px solid #fdfdff;
                background-color: #305676;
                // border: 2px solid #4e657b;
            }
        }

        @media (max-width: 550px) {
            gap: 25px;
            margin-top: 4em;

            .toggle-tab { 
                padding-bottom: 1em;
            }
        }
    }
    .select-outlet {
        padding: 16px 18px;
        background: #305676;
        border-radius: 12px;

        @media (min-width: 991px) {
            max-width: 450px;
        }

        @media (max-width: 550px) {
            width: auto;
        }

        text-align: center;
        color: $primary_text_color;
        font-size: 18px;
        font-family: 'poppins-semibold';

        span {
            color: $secondary_text_color;
        }
    }

    //new year
    .lion-dance {
        position: absolute;
        width: 5em;
        right: -12px;
        height: auto;
        top: -16px;
        transform: rotate(10deg);

        &.--left {
            left: -12px;
            top: -18px;
            transform: rotate(340deg);
        }

        @media (min-width: 550px) {
            width: 5.5em;
            right: -12px;
            height: auto;
            top: -23px;

            &.--left {
                left: -21px;
                top: -23px;
                transform: rotate(340deg);
            }
        }

        @media (min-width: 1400px) {
            width: 5.5em;
            right: 40px;
            height: auto;
            top: -23px;

            &.--left {
                left: 30px;
                top: -23px;
                transform: rotate(340deg);
            }
        }
    }

    // .gold {
    //     position: absolute;
    //     width: 4.5em;
    //     left: -10px;
    //     height: auto;
    //     bottom: -10px;

    //     @media (min-width: 991px) {
    //         width: 6em;
    //         right: -3px;
    //         height: auto;
    //         top: -27px;
    //     }
    // }

    .outlet-tab {
        width: 100%;
        padding: 1em;
        border-radius: 1em;
        background-color: $primary_text_color;

        .outlet-line {
            width: 95%;
            padding-bottom: 0.5em;
            border-bottom: 1px solid #c1c1c1;
        }

        @media (max-width: 550px) {
            padding: 0.8em 0em;
        }

        @media (min-width: 1100px) {
            max-width: 500px;
        }

        &.--closed {
            position: relative;
            opacity: 0.9;
            display: flex;
            justify-content: center;

            img {
                opacity: 0.1;
            }

            label {
                opacity: 0.1;

            }

            .outlet-line {
                opacity: 0.1;
            }

            .closed-text {
                position: absolute;
                font-size: 16px;
                font-family: 'poppins-semibold';
                color: #495057;
                opacity: 1;
                z-index: 2;
            }
        }
    }

    ._label {
        font-family: $primary_font;

        &.--option-label {
            color: white;
            font-size: 19px;
            font-family: 'poppins-semibold';

            &.active {
                color: $secondary_text_color;
            }

            @media (max-width: 550px) {
                font-size: 16px;
            }
        }

        &.--outlet {
            //new year
            
            font-size: 16px;
            color: $primary_background_color;
            font-family: 'poppins-bold';
            
            @media (max-width: 550px) {
                width: 12em;
                line-height: 1.4em;
                font-size: 14px;
            }
            
            span {
                color: $primary_text_color;
            }
        }

        &.--address {
            color: #808080;
            font-size: 14px;

            span {
                color: $primary_background_color;
                font-family: 'poppins-medium';

                display: -webkit-inline-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;

                @media (max-width: 550px) {
                    font-size: 12px;
                    height: 38.38px;
                }
    
                height: 44.78px;
            }

            @media (max-width: 550px) {
                font-size: 12px;
            }
        }

        &.--distance {
            color: #808080;
            font-size: 14px;

            @media (max-width: 550px) {
                font-size: 12px;
            }
        }
    }
}

.set-address-container {
    max-width: none !important; 
    // background-color: $primary_bg_color;
    padding: 70px 10px 10px !important;
    box-shadow: 0 1px 10px #ebebeb !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 2em;   
    }

    @media (min-width: 991px) {
        padding: 20px 30px 80px !important;
        margin-top: 0em;
        box-shadow: unset !important;
    }

    .search-bar {
        display: flex;
        padding: 11px 20px;
        background: #e3e3e3;
        border-radius: 60px; 
        justify-content: space-between;
        align-items: center;

        color: #878585;
        font-size: 14px;
        font-family: 'poppins-medium';
        font-weight: 500;

        input {
            padding-top: 3px;
            width: 100%;
            border: none;
            outline: none !important;
            background: #e3e3e3;
        }
    }

    .address-listing {
        .empty-address {
            text-align: center;
            margin-top: 6em;
        }

        .addresses {
            border-bottom: 1px solid #BBB6B6;

            // .menu {
            //     width: 5em;
            //     position: absolute;
            //     background-color: $primary_text_color;
            //     padding: 0.5em 1em;

            //     font-size: 14px;

            // }
        }
    }

    ._label {
        color: $primary_text_color;
        font-family: 'poppins-medium';

        &.--saved-title {
            font-size: 18px;
        }
        &.--saved-desc {
            color: #9EBCD8;
            font-size: 14px;
        }
        &.--add-address {
            font-size: 14px;
            font-family: 'poppins';
        }

        &.--address-name {
            color:  $primary_text_color;
            font-size: 14px;
            font-family: 'poppins';
            line-height: 14.40px;
        }

        &.--address {
            color: $gray-text;
            font-size: 12px;
            font-family: 'poppins';
        }
    }
}

.save-address-container {
    max-width: none !important; 
    // background-color: $primary_bg_color;
    padding: 70px 10px 5px !important;
    box-shadow: 0 1px 10px #ebebeb !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 2em;   
    }

    @media (min-width: 991px) {
        padding: 20px 30px 20px !important;
        margin-top: 0em;
        box-shadow: unset !important;
    }

    .image-frame {
        &.--map {
            width: 100%;
            height: 165px;
            padding: 0em 1.5em;

            img {
                border-radius: 14px;
            }
        }
    }

    .rider-note {
        padding: 10px; 
        border-radius: 11px; 
        border: 1px #A6A6A6 solid;
    }

    ._label {
        color: $primary_text_color;
        font-family: 'poppins-medium';

        &.--add-note {
            font-size: 14px;
            font-family: 'poppins';
            color: #C1C1C1;
            margin-left: 0.2em;
        }
        &.--info-title {
            font-size: 18px;
        }
        &.--saved-desc {
            color: #9EBCD8;
            font-size: 14px;
        }
        &.--add-address {
            font-size: 14px;
            font-family: 'poppins';
        }

        &.--selected-address {
            color: $secondary_text_color;
            font-size: 15px;
            font-family: 'poppins';
        }
    }
}

.login-container {
    padding: 0em 2em 3em !important;

    .image-frame {
        padding: 0;
        &.--logo {
            width: 235px;
            height: 62px;
        }
    }

    ._label {
        color: $primary_text_color;
        font-family: 'poppins';

        &.--login-title {
            color: #fafafa;
            font-size: 20px;
        }    

        &.--login-tc {
            font-size: 12px;
            line-height: 24px;

            span {
                color: $secondary_text_color;
            }
        }
        &.--request-otp {
            color: #EEEEEE;
            font-size: 18px;
            line-height: 22px;
            text-decoration: underline;

            text-align: center;
            margin: 2em 0em 0.5em;
        }
    }

    .input-container {
        @media (min-width: 550px) {
            padding: 0em 6em;
        }

    }

    input[type=checkbox] {
        position: relative;
        width: 0px;
        height: 0px;
    }
    
    input[type=checkbox]:checked:before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border: 2px solid  $secondary_text_color;
        border-radius: 20px;
        background-color: #445768;  
        transition: all 0.2s linear;
        top: 1px;
        left: 1px;
    }
    
    
    input[type=checkbox]:before {
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        border: 2px solid rgba(214, 214, 214, 1);
        border-radius: 3px;
        background-color: transparent;
        top: 1px;
        left: 1px;
    }
    
    
    input[type=checkbox]:after {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        border: solid $secondary_text_color;
        border-width: 0 0px 0px 0;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        position: absolute;
        top: 1px;
        left: 50px;
        transition: all 0.2s linear;
    }
    
    input[type=checkbox]:checked:after {
        content: "";
        display: block;
        width: 6px;
        height: 13px;
        border: 2px solid  $secondary_text_color;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: absolute;
        top: 0px;
        left: 8px;
    }
}

.p-dropdown-panel {
    border-top-left-radius: 0;
    .p-dropdown-items-wrapper {
        .p-dropdown-items {
            padding: 0;

            li {
                padding: 0.8em;

                font-size: 13px;
                color: $primary_background_color;
                font-family: 'poppins-medium';
            }
        }
    }
}

.tnc-container {
    padding: 80px 0px 150px !important;
    margin-top: 4em;

    @media (max-width: 550px) {
        margin-top: 1.5em;   
    }

    .policy-dropdown {
        position: relative;
        top: 10%;
        z-index: 99;
        left: 0;
        width: 100%;
        padding: 3em;
        outline: 0;

        &.--fixed {
            position: fixed;
        }

        .p-inputwrapper-focus {
            border: 0;
        }
        
        .p-dropdown {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 1em 2em;
            background-color: #eaf5ffab;
            backdrop-filter: blur(5px);

            .p-dropdown-label{
                font-size: 16px;
                font-family: 'poppins-semibold';
                color: $primary_text_color;
            }  
        }

        @media (max-width: 550px) {
            top: 6%;  
        }
    }

    .policy-scroll {
        position: fixed;
        text-align: left;
        top: unset !important;

        .nav-pills {
            .nav-item {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: 'poppins';
                    color: #e3e3e3;

                    padding: 0.8em 1.3em;
                    display: flex;
                    align-items: center;
                    border: 0;

                    &.--active {
                        margin: 0.7em 0em;
                        padding: 0.2em 1em;
                        font-size: 16px;
                        color: $secondary_text_color;
                        font-family: 'poppins-semibold';
                        border-left: 1px solid $primary_text_color;
                    }

                    transition: all 0.4s;
            }
        }
    }

    ._label {
        &.--main-title {
            font-size: 30px;
            font-family: 'poppins-semibold';
            color: $secondary_text_color;
            letter-spacing: 0.02em;
        }
        &.--main-subtitle {
            font-size: 15px;
            font-family: 'poppins';
            color: #e3e3e3;
        }

        &.--section-title {
            font-size: 20px;
            font-family: 'poppins-semibold';
            color: $secondary_text_color;
        }

        &.--tnc-title {
            font-size: 18px;
            font-family: 'poppins-medium';
            color: $tertiary_text_color;
        }
        &.--tnc-content {
            font-size: 15px;
            font-family: 'poppins';
            color: #e3e3e3;
            padding-left: 1.85em;
        }
    }
}

.button-container{
    padding: 1.8em;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: $primary_background_color;

    @media (min-width: 991px) {
        width: 55em;
        bottom: 1em;
    }

    button {
        border-radius: 8px;
        padding: 1em 5em 1em 5em;
        font-size: 14px;
        width: 100%;

        @media screen and (max-width: 991px) {
            padding: 1em 3.5em 1em 3.5em;
        }
    }

    .estimated-delivery {
        padding: 0.6em 2em;
        border-radius: 6px;
        background: #305676cc;
        backdrop-filter: blur(10px);
        // max-width: 350px;

        font-size: 16px;
        font-family: 'cabin';
        color: $primary_text_color;

        svg {
            width: 18px;
            height: 18px;
            padding-bottom: 0.1em;
        }

        @media (min-width: 991px) {
            font-size: 16px;
            padding: 0.6em 2em;

            svg {
                width: 20px;
                height: 20px;
                padding-bottom: 0.1em;
            }
        }

        @media (max-width: 550px) {
            font-size: 13px;
            padding: 0.6em 2em;
            padding: 1em 0.6em;
        }
    }

    &.--full-with-bg {
        z-index: 10;
        background-color: $primary_background_color !important;
        box-shadow: 0px 2px 22px #004281;
        // background-color: #113b6387 !important;
        // backdrop-filter: blur(5px);

        button {
            padding: 0.7em 1em;
        }

        label  {
            font-size: 16px;
            font-family: 'poppins-semibold';
        }

        .quantity-circle {
            padding: 4px 8px;
            border-radius: 50px;
            border: 1.5px $primary_text_color solid;
    
            color: $primary_text_color;
            font-size: 14px;
            font-family: 'poppins-bold';

            span {
                font-size: 11px;
            }
        }
    
    }    

    @media (min-width: 991px) {
        &.--full {
            background-color: transparent;
            width: 70em;
        }    

        &.--full-with-bg {
            width: 70em;
            background-color: transparent !important;
            box-shadow: unset !important;

            button {
                padding: 0.55em 1.8em;
            }
    
            label  {
                font-size: 18px;
            }
    
            .quantity-circle {
                padding: 5px 9px;
                font-size: 16px;
    
                span {
                    font-size: 12px;
                }
            }
        }    
    }

    &.--blue {
        position: sticky;
        border-top: 1px solid $primary_text_color;
        margin-top: 4em;
        bottom: 0 !important;
        
        button {
            margin-top: 0em;
            margin-bottom: 0em;
            background-color: $secondary_background_color !important;
        }

        @media (min-width: 991px) {
            width: 100%;
        }
    }

    &.--white {
        background-color: $primary_text_color;
        border-bottom-left-radius: 1.5em;
        border-bottom-right-radius: 1.5em;
    }

    &.--transparent {
        background-color: transparent;
    }

    &.--notFixed {
        position: relative !important;

        @media (min-width: 991px) {
            width: auto;
        }

        &.address {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.--yesNo {
        position: relative;
        background: transparent;
        display: block;
        padding: 1em 8em 3em;

        @media (min-width: 991px) {
            padding: 1em 13em 3em;
        }

        button {
            background-color: $secondary_background_color !important;
        }

        &.delete {
            button {
                background-color: transparent !important;
                color: #ff2b35;
                border: 1px solid #ff2b35;
            }
        }
    }
}

.pac-container {
    // display: block !important;
    z-index: 9999 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 49em !important;
    background-color: #e3e3e3;
    box-shadow: unset;
    border-top: none !important;
    border-radius: 10px;
    // border-bottom-right-radius: 25px;
    margin-top: 2.2em;

    @media (max-width: 991px) {
        // top: 32% !important;
        left: 50% !important;
        width: 95% !important;
        transform: translateX(-50%) !important;
    }

    .pac-item {
        height: 4em;
        display: flex;
        align-items: center;
        gap: 3px;
        cursor: pointer;

        span {
            margin: 0;
        }
    }

    &::after {
        display: none !important;
    }
}

.error-msg {
    margin-left: 2em;
    font-size: 12px;
    color: #ff4444;
    font-family: 'poppins-semibold';

    span {
        text-decoration: underline;
    }
}

// .p-skeleton {
//     overflow: hidden !important;
//     position: relative !important;
// }

.blue-skeleton {
    overflow: hidden !important;
    background-color: #3b6b93 !important;
}

.grey-skeleton {
    overflow: hidden !important;
    background-color: #305676 !important;
}

.full-page-loading {
    background-color: #d7dee3d3;
    min-height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 102;

    .lottie-frame {
        width: 25em;
        height: 100%;
        top: 43%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);

        @media (max-width: 991px) {
            top: 35%;
        }

        @media (max-width: 770px) {
            top: 30%;
        }
    }

    .loading-label {
        top: 59%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);

        font-size: 16px;
        color: #3f3f3f;
        font-family: 'poppins-semibold';

        span {
            font-family: 'poppins-bold';
            color: $primary_background_color;
        }

        @media (max-width: 991px) {
            top: 50%;
        }

        @media (max-width: 770px) {
            width: 20em;
            top: 52%;
        }
    }
}

.custom-tooltip {
    color: #fdfdff !important;
    border: 1px solid #ccc;
    font-size: 12px !important;  
    border-radius: 0.5em !important;
    background: #305676 !important;
    border: none !important;
    font-family: 'poppins-medium';
    padding-top: 0.4em !important;
    padding-bottom: 0.45em !important;
}

.earth-icon {
    position: absolute;
    z-index: 2;
    background-color: #fdfdffb6;
    backdrop-filter: blur(5px);
    padding: 0.7em;
    border-radius: 100%;
}

.promo-swiper {
    height: 280px;
    border-radius: 15px;
    box-shadow: 2px 6px 15px #17324d;

    .swiper-pagination {
        .swiper-pagination-bullet {
            background: #a2d2ff4a;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background: $secondary_text_color;
            opacity: 1;
        }
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 90px;
        // background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #57050e91);
        // background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
        pointer-events: none;
        z-index: 2;

        @media (max-width: 550px) {
            height: 50px;
        }
    }

    @media (max-width: 600px) {
        border-radius: unset !important;
    }
}

.lottie-loading {
    .lottie-frame {
        width: 22em;
        height: 22em;

        svg {
            opacity: 0.8;
        }

        .image-frame {
            width: 22em;
            height: 22em;
        }

        &.--empty {
            .image-frame {
                margin-top: 4em;
                width: 10em;
                height: 10em;
            }
        }

        &.--order-empty {
            .image-frame {
                margin-top: 8em;
                width: 10em;
                height: 10em;
            }
        }

        &.--receipt {
            .image-frame {
                margin-top: 8em;
                width: 22em;
                height: 22em;
            }
        }
    }

    .loading-label {
        margin-top: -3em;
        font-family: 'poppins-medium';
        font-size: 13px;
        color: #9EBCD8;

        &.--empty {
            margin-top: -4em;
        }

        &.--receipt {
            margin-top: -2em;
            color: $primary_background_color;
        }
    }
}

.infinite-scroll-component  {
    padding: 70px 20px 50px !important;

    @media (min-width: 991px) {
        padding: 10px 30px 70px !important;
    }

    &.--withdraw {
        // height: auto !important;
        // overflow: unset !important;
        padding: 10px 10px 20px !important;

        @media (min-width: 991px) {
            padding: 10px 10px 20px !important;
        }

        &.unscrollable{
            // overflow: unset !important;
        }
    }
}

.infinite-scroll-component::-webkit-scrollbar {
    display: none;
}

.infinite-loading-label {
    text-align: center;
    font-family: 'poppins-medium';
    font-size: 13px;
    color: $secondary_text_color;
    letter-spacing: 0.03em;

    &.--nothing {
        color: $tertiary_text_color;
    }
}

#canvas {
    position: absolute;
    z-index: -1;
    scale: 1.2;
}

// .chinese-new-year-container {
//     position: relative;
//     width: 100%;
//     height: 100vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     font-family: 'Your-Chinese-Font', sans-serif; /* Replace 'Your-Chinese-Font' with the actual font */
//     color: #ff4500; /* Adjust the color as needed */
//   }
  
.deng-box {
    // position: absolute;
    // top: -40px;
    position: absolute;
    left: 80%;
    z-index: 0;
    bottom: 76px;

    &.--tab {
        left: 70%;
    }

    &.--mobile {
        bottom: 45px;
        left: 65%;
    }
  }
  
  .deng-box1 {
    position: absolute;
    // top: -30px;
    left: -3%;
    z-index: 1999;
    bottom: 76px;
  }
  
  .deng-box1 .deng {
    position: relative;
    width: 120px;
    height: 90px;
    margin: 50px;
    background: #d8000f;
    background: rgba(216, 0, 15, 0.8);
    border-radius: 50% 50%;
    -webkit-transform-origin: 50% -100px;
    -webkit-animation: swing 5s infinite ease-in-out;
    animation: swing 5s infinite ease-in-out;
    box-shadow: -5px 5px 30px 4px rgba(252, 144, 61, 1);
  }
  
  .deng {
    position: relative;
    width: 120px;
    height: 90px;
    margin: 50px;
    background: #d8000f;
    background: rgba(216, 0, 15, 0.8);
    border-radius: 50% 50%;
    -webkit-transform-origin: 50% -100px;
    -webkit-animation: swing 3s infinite ease-in-out;
    animation: swing 3s infinite ease-in-out;
    box-shadow: -5px 5px 50px 4px rgba(250, 108, 0, 1);
  }
  
  .deng-a {
    width: 100px;
    height: 90px;
    background: #d8000f;
    background: rgba(216, 0, 15, 0.1);
    margin: 12px 8px 8px 10px;
    border-radius: 50% 50%;
    border: 2px solid #dc8f03;
  }
  
  .deng-b {
    width: 45px;
    height: 90px;
    background: #d8000f;
    background: rgba(216, 0, 15, 0.1);
    margin: -4px 8px 8px 26px;
    border-radius: 50% 50%;
    border: 2px solid #dc8f03;
  }
  
  .xian {
    position: absolute;
    top: -25px;
    left: 60px;
    width: 2px;
    height: 20px;
    background: #dc8f03;
  }
  
  .shui-a {
    position: relative;
    width: 5px;
    height: 20px;
    margin: -5px 0 0 59px;
    -webkit-animation: swing 4s infinite ease-in-out;
    -webkit-transform-origin: 50% -45px;
    background: #ffa500;
    border-radius: 0 0 5px 5px;
  }
  
  .shui-b {
    position: absolute;
    top: 14px;
    left: -2px;
    width: 10px;
    height: 10px;
    background: #dc8f03;
    border-radius: 50%;
  }
  
  .shui-c {
    position: absolute;
    top: 18px;
    left: -2px;
    width: 10px;
    height: 35px;
    background: #ffa500;
    border-radius: 0 0 0 5px;
  }
  
  .deng:before {
    position: absolute;
    top: -7px;
    left: 29px;
    height: 12px;
    width: 60px;
    content: " ";
    display: block;
    z-index: 1999;
    border-radius: 5px 5px 0 0;
    border: solid 1px #dc8f03;
    background: #ffa500;
    background: linear-gradient(
      to right,
      #dc8f03,
      #ffa500,
      #dc8f03,
      #ffa500,
      #dc8f03
    );
  }
  
  .deng:after {
    position: absolute;
    bottom: -7px;
    left: 10px;
    height: 12px;
    width: 60px;
    content: " ";
    display: block;
    margin-left: 20px;
    border-radius: 0 0 5px 5px;
    border: solid 1px #dc8f03;
    background: #ffa500;
    background: linear-gradient(
      to right,
      #dc8f03,
      #ffa500,
      #dc8f03,
      #ffa500,
      #dc8f03
    );
  }
  
  .deng-t {
    font-family: 华文行楷, Arial, Lucida Grande, Tahoma, sans-serif;
    font-size: 3.2rem;
    color: #dc8f03;
    font-weight: bold;
    line-height: 85px;
    text-align: center;
  }
  
  .night .deng-t,
  .night .deng-box,
  .night .deng-box1 {
    background: transparent !important;
  }
  
  @keyframes swing {
    0% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(-10deg);
    }
  }
  
  @-moz-keyframes swing {
    0% {
      -moz-transform: rotate(-10deg);
    }
    50% {
      -moz-transform: rotate(10deg);
    }
    100% {
      -moz-transform: rotate(-10deg);
    }
  }
  
  @-webkit-keyframes swing {
    0% {
      -webkit-transform: rotate(-10deg);
    }
    50% {
      -webkit-transform: rotate(10deg);
    }
    100% {
      -webkit-transform: rotate(-10deg);
    }
  }

//   .small-lantern {
//     position: absolute;
//     /* Adjust the left and bottom values as needed */
//     // left: 5%;
//     bottom: 29%;

//     &.--two {
//         left: 0%;
//     }

//     &.--one {
//         left: 82%;
//     }

//     // &.--three {
//     //     left: 33%;
//     // }

//     // &.--four {
//     //     left: 46%;
//     // }

//     // &.--five {
//     //     left: 72%;
//     // }
//   }
  
//   .small-lantern .deng {
//     position: relative;
//     width: 60px;
//     height: 45px;
//     margin: 25px; /* Adjust the margin as needed */
//     background: #d8000f;
//     background: rgba(216, 0, 15, 0.8);
//     border-radius: 50% 50%;
//     -webkit-transform-origin: 50% -50px;
//     -webkit-animation: swing 3s infinite ease-in-out; /* Adjust the animation duration */
//     animation: swing 3s infinite ease-in-out;
//     box-shadow: -3px 3px 25px 4px rgba(250, 108, 0, 1); /* Adjust the box shadow */
//   }
  
//   .small-lantern .deng-a {
//     width: 50px;
//     height: 45px;
//     background: #d8000f;
//     background: rgba(216, 0, 15, 0.1);
//     margin: 8px 5px 5px 5px; /* Adjust the margin as needed */
//     border-radius: 50% 50%;
//     border: 2px solid #dc8f03;
//   }
  
//   .small-lantern .deng-b {
//     width: 25px;
//     height: 45px;
//     background: #d8000f;
//     background: rgba(216, 0, 15, 0.1);
//     margin: -2px 5px 5px 13px; /* Adjust the margin as needed */
//     border-radius: 50% 50%;
//     border: 2px solid #dc8f03;
//   }
  
//   .small-lantern .xian {
//     position: absolute;
//     top: -12.5px;
//     left: 30px;
//     width: 1px;
//     height: 10px;
//     background: #dc8f03;
//   }
  
//   .small-lantern .shui-a {
//     position: relative;
//     width: 2.5px;
//     height: 10px;
//     margin: -2.5px 0 0 29px;
//     -webkit-animation: swing 2s infinite ease-in-out; /* Adjust the animation duration */
//     -webkit-transform-origin: 50% -22.5px;
//     background: #ffa500;
//     border-radius: 0 0 2.5px 2.5px;
//   }
  
//   .small-lantern .shui-b {
//     position: absolute;
//     top: 7px;
//     left: -1px;
//     width: 5px;
//     height: 5px;
//     background: #dc8f03;
//     border-radius: 50%;
//   }
  
//   .small-lantern .shui-c {
//     position: absolute;
//     top: 9px;
//     left: -1px;
//     width: 5px;
//     height: 17.5px;
//     background: #ffa500;
//     border-radius: 0 0 0 2.5px;
//   }
  
//   .small-lantern .deng:before {
//     position: absolute;
//     top: -3.5px;
//     left: 14.5px;
//     height: 6px;
//     width: 30px;
//     content: " ";
//     display: block;
//     z-index: 1999;
//     border-radius: 2.5px 2.5px 0 0;
//     border: solid 1px #dc8f03;
//     background: #ffa500;
//     background: linear-gradient(
//       to right,
//       #dc8f03,
//       #ffa500,
//       #dc8f03,
//       #ffa500,
//       #dc8f03
//     );
//   }
  
//   .small-lantern .deng:after {
//     position: absolute;
//     bottom: -3.5px;
//     left: 5px;
//     height: 6px;
//     width: 30px;
//     content: " ";
//     display: block;
//     margin-left: 10px;
//     border-radius: 0 0 2.5px 2.5px;
//     border: solid 1px #dc8f03;
//     background: #ffa500;
//     background: linear-gradient(
//       to right,
//       #dc8f03,
//       #ffa500,
//       #dc8f03,
//       #ffa500,
//       #dc8f03
//     );
//   }
  
//   .small-lantern .deng-t {
//     font-family: 华文行楷, Arial, Lucida Grande, Tahoma, sans-serif;
//     font-size: 1.6rem; /* Adjust the font size */
//     color: #dc8f03;
//     font-weight: bold;
//     line-height: 42.5px; /* Adjust the line height */
//     text-align: center;
//   }

  .new-year-graphic {
    position: absolute;
    top: 38.5%;
    object-fit: contain;
    z-index: -2;
  }

//   .extra-small-lantern {
//     position: absolute;
//     bottom: 70%; // Adjust the bottom value as needed
  
//     &.--one {
//         left: -55%;
//         transform: translateX(50%);
//     }

//     &.--two {
//         left: -33%;
//         transform: translateX(50%);
//     }

//     &.--three {
//         left: -12.5%;
//         transform: translateX(50%);
//     }

//     &.--four {
//         left: 8.5%;
//         transform: translateX(50%);
//     }

//     &.--five {
//         left: 29%;
//         transform: translateX(50%);
//     }
  
//     .deng {
//       position: relative;
//       width: 45px; // Adjusted width
//       height: 33.75px; // Adjusted height to maintain proportion
//       margin: 18.75px; // Adjusted margin
//       background: rgba(216, 0, 15, 0.8);
//       border-radius: 50%;
//       transform-origin: 50% -37.5px;
//       animation: swing 3s infinite ease-in-out;
//       box-shadow: -2.25px 2.25px 18.75px 3px rgba(250, 108, 0, 1);
//     }
  
//     .deng-a {
//       width: 37.5px; // Adjusted width
//       height: 33.75px; // Adjusted height
//       margin: 6px 3.75px 3.75px 3.75px; // Adjusted margin
//       border-radius: 50%;
//       border: 1.5px solid #dc8f03;
//     }
  
//     .deng-b {
//       width: 18.75px; // Adjusted width
//       height: 33.75px; // Adjusted height
//       margin: -1.5px 3.75px 3.75px 9.75px; // Adjusted margin
//       border-radius: 50%;
//       border: 1.5px solid #dc8f03;
//     }
  
//     .xian {
//       position: absolute;
//       top: -9.375px;
//       left: 22.5px;
//       width: 0.75px;
//       height: 7.5px;
//       background: #dc8f03;
//     }
  
//     .shui-a {
//       position: relative;
//       width: 1.875px;
//       height: 7.5px;
//       margin: -1.875px 0 0 21.75px;
//       animation: swing 2s infinite ease-in-out;
//       transform-origin: 50% -16.875px;
//       background: #ffa500;
//       border-radius: 0 0 1.875px 1.875px;
//     }
  
//     .shui-b {
//       position: absolute;
//       top: 5.25px;
//       left: -0.75px;
//       width: 3.75px;
//       height: 3.75px;
//       background: #dc8f03;
//       border-radius: 50%;
//     }
  
//     .shui-c {
//       position: absolute;
//       top: 6.75px;
//       left: -0.75px;
//       width: 3.75px;
//       height: 13.125px;
//       background: #ffa500;
//       border-radius: 0 0 0 1.875px;
//     }
  
//     .deng:before {
//       position: absolute;
//       top: -2.625px;
//       left: 10.875px;
//       height: 4.5px;
//       width: 22.5px;
//       content: " ";
//       display: block;
//       z-index: 1999;
//       border-radius: 1.875px 1.875px 0 0;
//       border: solid 0.75px #dc8f03;
//       background: linear-gradient(to right, #dc8f03, #ffa500, #dc8f03, #ffa500, #dc8f03);
//     }
  
//     .deng:after {
//       position: absolute;
//       bottom: -2.625px;
//       left: 3.75px;
//       height: 4.5px;
//       width: 22.5px;
//       content: " ";
//       display: block;
//       margin-left: 7.5px;
//       border-radius: 0 0 1.875px 1.875px;
//       border: solid 0.75px #dc8f03;
//       background: linear-gradient(to right, #dc8f03, #ffa500, #dc8f03, #ffa500, #dc8f03);
//     }
  
//     .deng-t {
//       font-size: 1.2rem; // Adjusted font size
//       line-height: 31.875px; // Adjusted line height
//       text-align: center;
//     }
//   }

// 30px
//   .extra-small-lantern {
//     position: absolute;
//     bottom: 78%; // Adjust the bottom value as needed
//     // Define positions for each lantern
//     &.--two { 
//         left: 18%; 
//     }
//     &.--four { 
//         left: 46%; 
//     }
  
//     .deng {
//       position: relative;
//       width: 30px; // 50% smaller
//       height: 22.5px; // 50% smaller
//       margin: 12.5px; // Adjust the margin as needed
//       background: rgba(216, 0, 15, 0.8);
//       border-radius: 50%;
//       transform-origin: 50% -25px;
//       animation: swing 3s infinite ease-in-out;
//       box-shadow: -1.5px 1.5px 12.5px 2px rgba(250, 108, 0, 1);
//     }
  
//     .deng-a {
//       width: 25px; // 50% smaller
//       height: 22.5px; // 50% smaller
//       margin: 4px 2.5px 2.5px 2.5px; // Adjust the margin as needed
//       border-radius: 50%;
//       border: 1px solid #dc8f03;
//     }
  
//     .deng-b {
//       width: 12.5px; // 50% smaller
//       height: 22.5px; // 50% smaller
//       margin: -1px 2.5px 2.5px 6.5px; // Adjust the margin as needed
//       border-radius: 50%;
//       border: 1px solid #dc8f03;
//     }
  
//     .xian {
//       position: absolute;
//       top: -6.25px;
//       left: 15px;
//       width: 0.5px;
//       height: 5px;
//       background: #dc8f03;
//     }
  
//     .shui-a {
//       position: relative;
//       width: 1.25px;
//       height: 5px;
//       margin: -1.25px 0 0 14.5px;
//       animation: swing 2s infinite ease-in-out;
//       transform-origin: 50% -11.25px;
//       background: #ffa500;
//       border-radius: 0 0 1.25px 1.25px;
//     }
  
//     .shui-b {
//       position: absolute;
//       top: 3.5px;
//       left: -0.5px;
//       width: 2.5px;
//       height: 2.5px;
//       background: #dc8f03;
//       border-radius: 50%;
//     }
  
//     .shui-c {
//       position: absolute;
//       top: 4.5px;
//       left: -0.5px;
//       width: 2.5px;
//       height: 8.75px;
//       background: #ffa500;
//       border-radius: 0 0 0 1.25px;
//     }
  
//     .deng:before {
//       position: absolute;
//       top: -1.75px;
//       left: 7.25px;
//       height: 3px;
//       width: 15px;
//       content: " ";
//       display: block;
//       z-index: 1999;
//       border-radius: 1.25px 1.25px 0 0;
//       border: solid 0.5px #dc8f03;
//       background: linear-gradient(to right, #dc8f03, #ffa500, #dc8f03, #ffa500, #dc8f03);
//     }
  
//     .deng:after {
//       position: absolute;
//       bottom: -1.75px;
//       left: 2.5px;
//       height: 3px;
//       width: 15px;
//       content: " ";
//       display: block;
//       margin-left: 5px;
//       border-radius: 0 0 1.25px 1.25px;
//       border: solid 0.5px #dc8f03;
//       background: linear-gradient(to right, #dc8f03, #ffa500, #dc8f03, #ffa500, #dc8f03);
//     }
  
//     .deng-t {
//       font-size: 0.8rem; // 50% smaller
//       line-height: 21.25px; // Adjust the line height
//       text-align: center;
//     }
//   }