@import "../abstract/variables";

.element {
    &._button {
        font-size: 14px;
        font-family: $primary_font;
        
        label {
        }
        
        button {
            font-size: 18px;
            font-family: 'poppins-semibold'; 
            border-radius: 12px;
            padding: 0.8em 1.8em;
            text-transform: capitalize;
            
            
            &:disabled {
                background-color: $tertiary_text_color !important;
            }

            &:active {
                background-color: $tertiary_text_color !important;
            }
        }

        .solid-button{
            outline: none;
            background-color: $secondary_text_color;
            box-shadow: none !important;

            &.--blue {
                background-color: #006294  !important;
                color: $primary_text_color;
                border: none !important;
            }
        } 

        .outline-button{
            border: 1px solid $tertiary_text_color;
            color: $tertiary_text_color;
            background-color: transparent !important;
            box-shadow: none !important;
        }

    }
    &._input-count {
        .count-button {
            display: flex;
            align-items: center;
            text-align: center;
            background: #305676;
            border-radius: 12px;
            padding: 1.2em 1.8em;

            label {
                color: $primary_text_color;
                font-size: 16px;
                font-family: 'poppins-semibold';
            }

            svg {
                width: 30px;
                height: 25px;
            }
        }
        
        &.--smaller {
            margin-top: 1em;

            .count-button {
                display: flex;
                align-items: center;
                text-align: center;
                background: #305676;
                border-radius: 8px;
                padding: 6px 10px;
    
                label {
                    color: $primary_text_color;
                    font-size: 13px;
                    font-family: 'poppins-semibold';
                }

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

    }
    &._code-input {
        label {
            font-size: 14px;
            font-family: 'poppins';
            color: #C1C1C1;
            margin: 1em 0em 0.5em 0.1em;
            span {
                color: #EE5B5B;
            }
        }

        .ReactInputVerificationCode__container{
            width: 100%;
            padding: 0em 0em 0em 1em;
            color: $primary_text_color !important;

            
            .ReactInputVerificationCode__item{
                border-radius: 0;
                box-shadow: none !important;
                border-bottom: 0.1rem solid $secondary_text_color !important;
                @media (max-width: 550px) {
                    width: 14%;
                }
            }
            .ReactInputVerificationCode__item.is-active{
                border-radius: 0;
                box-shadow: none !important;
                // border: 0.2rem solid $secondary_text_color !important;
            }
        }
    }
    &._text{
        font-size: 16px;
        font-family: $primary_font;
        outline: none !important;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            caret-color: $primary_text_color;
            -webkit-text-fill-color: $primary_text_color;
            -webkit-background-clip: text;
            transition: background-color 5000s ease-in-out 0s;
        }

        // input {
        //     width: 100% !important;
        // }


        label {
            font-size: 14px;
            font-family: 'poppins';
            color: #C1C1C1;
            margin-left: 0.2em;
            span {
                color: #EE5B5B;
            }
        }

        .prefix {
            background: #113b63;

            label {
                color: #fdfdff !important;
                font-size: 16px;
                font-family: 'poppins';
                line-height: 22px;
            }
        }



        &.mdb-field {
            margin: 1rem 0;
            margin-bottom: 0.1rem;
            border-radius: 2em;
            border: 2px solid #dfdfdf;
            background-color: $primary_text_color !important;
            color: $tertiary_text_color;
        
            .form-outline {
                .form-label {
                    background-color: transparent;
                    padding: 1.4em 0.5em 0em;
                    margin-left: 0.5em;

                    color: #5C5C5C;
                    font-size: 14px;
                    font-family: 'poppins-medium';
                    line-height: 16px;

                }
                .form-control {
                    min-height: 3em;
                    padding: 1.8em 1em 0.5em;
                    color: $primary_background_color !important;
                    font-size: 14px;
                    font-family: 'poppins-medium';
                }

                .form-control[readonly] {
                    background-color: $primary_text_color;
                    border-radius: 2em;
                }
                
                .form-control:disabled{
                    background-color: transparent !important;
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                caret-color: $primary_background_color;
                -webkit-text-fill-color: $primary_background_color;
                -webkit-background-clip: text;
            }
            
            &.--disabled{
                opacity: 0.7;
                background-color: #f1f1f1;

                .form-outline {
                    .form-label {
                        background-color: #f1f1f1;
                    }
                }
            }

            &.--error{
                border: 2px solid #ff5c5c6b;

                .form-outline {
                    .form-label {
                    color: #ff5c5c !important;
                    }
                    .form-control {
                        color: #ff5c5c;
                    }
                }
            }

            &.--autofill {
                background-color: #0c2945 !important;

                .form-outline {
                    .form-label {
                        color: $primary_text_color !important;
                    }
                    .form-control {
                        color: $primary_text_color !important;
                    }
                }
            }

            .form-notch {
                display: none !important;
            }  
        }

        &.line-field {
            margin: 0.5rem 0;
            margin-bottom: 0.1rem;
            border-bottom: 1.5px solid $secondary_text_color;
            outline: none;
           
            input {
                background-color: #113b63;
                padding: 0.5em 1em;
                color: $primary_text_color !important;
                font-size: 16px;
                font-family: 'poppins';
                line-height: 22px;
                border: none !important;
                outline: none !important;
                border-bottom: 1.5px solid $secondary_text_color;
                
                &::placeholder {
                    color: $tertiary_text_color !important; // Change this to the desired color
                }
            }

            .phone_no {
                padding: 0.6em 0.5em 0.5em;
                letter-spacing: 0.05em;
                width: 80%;
            }

            .phone_no::-webkit-outer-spin-button,
            .phone_no::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                caret-color: $primary_text_color;
                -webkit-text-fill-color: $primary_text_color;
                -webkit-background-clip: text;
                box-shadow: 0 0 0 30px #113b63 inset;
                transition: background-color 5000s ease-in-out 0s;
            }

            &.--white {
                border-bottom: 1.5px solid $primary_text_color;

                input {
                    font-size: 14px;
                    font-family: 'cabin';
                    border-bottom: 1.5px solid $primary_text_color;

                    &::placeholder {
                        color: #C3C4CD !important;
                    }
                }
            }

            &.--disabled{
                opacity: 0.7;
                background-color: #f1f1f1;

                .form-outline {
                    .form-label {
                        background-color: #f1f1f1;
                    }
                }
            }

            &.--error{
                border: 2px solid #ff5c5c6b;

                .form-outline {
                    .form-label {
                      color: #ff5c5c !important;
                    }
                    .form-control {
                        color: #ff5c5c;
                    }
                }
            }

            .form-notch {
                display: none !important;
            }  
        }

        &.round-field {
            margin: 0.5rem 0;
            margin-bottom: 0.1rem;
            border-radius: 28px;
            // border: 1.5px solid #c1c1c1;
           
            input {
                background-color: $primary_background_color;
                padding: 0.9em 1em;
                color: $primary_text_color;
                font-size: 14px;
                font-family: 'poppins';
                border: none !important;
                outline: none !important;
                border-radius: 28px;
                border: 1.5px solid #c1c1c1 !important;
                
                &::placeholder {
                    color: $tertiary_text_color !important;
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                caret-color: $primary_text_color;
                -webkit-text-fill-color: $primary_text_color;
                -webkit-background-clip: text;
                transition: background-color 5000s ease-in-out 0s;
                border: 1.5px solid $primary_background_color !important;
            }

            &.--autofill {
                input {
                    background-color: #0c2945 !important;
                    border: 1.5px solid #0c2945 !important;
                    // color: #C1C1C1;
                    // font-size: 14px;
                    // font-family: 'poppins';

                    // &::placeholder {
                    //     color: #C1C1C1 !important;
                    // }
                }
            }

            &.--grey {
                input {
                    color: $primary_text_color !important;
                    font-size: 14px;
                    font-family: 'poppins';

                    &::placeholder {
                        color: #C1C1C1 !important;
                    }
                }
            }

            &.--disabled{
                input {
                    opacity: 0.4;

                    .form-outline {
                        .form-label {
                            background-color: #f1f1f1;
                        }
                    }
                }
            }

            &.--error{
                border: 2px solid #ff5c5c6b;

                .form-outline {
                    .form-label {
                      color: #ff5c5c !important;
                    }
                    .form-control {
                        color: #ff5c5c;
                    }
                }
            }

            .form-notch {
                display: none !important;
            }  
        }

        &.withdraw-field {
            background-color: #fdfdff !important;
            margin: 0.5rem 0;
           
            input {
                width: 4em;
                color: $primary_background_color;
                font-size: 38px;
                font-family: 'poppins-semibold';
                outline: none !important;
                border: none !important;
                
                &::placeholder {
                    color: $tertiary_text_color !important;
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                caret-color: $primary_text_color;
                -webkit-text-fill-color: $primary_text_color;
                -webkit-background-clip: text;
                transition: background-color 5000s ease-in-out 0s;
                border: 1.5px solid $primary_background_color !important;
            }

            &.--disabled{
                input {
                    opacity: 0.4;

                    .form-outline {
                        .form-label {
                            background-color: #f1f1f1;
                        }
                    }
                }
            }

            &.--error{
                border: 2px solid #ff5c5c6b;

                .form-outline {
                    .form-label {
                      color: #ff5c5c !important;
                    }
                    .form-control {
                        color: #ff5c5c;
                    }
                }
            }

            .form-notch {
                display: none !important;
            }  
        }

        &.textarea {
            margin: 0.5rem 0;
            margin-bottom: 0.1rem;
           
            textarea {
                resize: none;
                height: 3em;
                overflow-y: hidden;
                background-color: transparent;
                padding: 0em 1em;
                
                color: $tertiary_text_color;
                font-size: 12px;
                font-family: 'poppins';
                border: none !important;
                outline: none !important;
                                
                &::placeholder {
                    color: $tertiary_text_color !important;
                }
            }
            
            &.--grey {
                textarea {
                    color: $primary_text_color !important;
                    font-size: 14px;
                    font-family: 'poppins';

                    &::placeholder {
                        color: #C1C1C1 !important;
                    }
                }
            }
            // input:-webkit-autofill,
            // input:-webkit-autofill:hover,
            // input:-webkit-autofill:focus,
            // input:-webkit-autofill:active {
            //     caret-color: $primary_text_color;
            //     -webkit-text-fill-color: $primary_text_color;
            //     -webkit-background-clip: text;
            //     transition: background-color 5000s ease-in-out 0s;
            //     border: 1.5px solid $primary_background_color !important;
            // }

            &.--disabled{
                opacity: 0.7;
                background-color: #f1f1f1;

                .form-outline {
                    .form-label {
                        background-color: #f1f1f1;
                    }
                }
            }

            &.--error{
                border: 2px solid #ff5c5c6b;

                .form-outline {
                    .form-label {
                      color: #ff5c5c !important;
                    }
                    .form-control {
                        color: #ff5c5c;
                    }
                }
            }

            .form-notch {
                display: none !important;
            }  
        }
    }
    &._checkbox {
        color: #afc7dd;
        font-family: 'cabin';
        font-size: 14px;
        
        .form-label {
            color: #333333 !important;
        }

        .checkbox-wrapper {
            --borderColor: #F9A01B; // change checkbox border color
            --borderWidth: .125em;
            --checkedColor: #F9A01B; // change checkbox border color
        }
        
        .checkbox-wrapper input[type=checkbox] {
            -webkit-appearance: none;
            appearance: none;
            vertical-align: middle;
            background: transparent;
            font-size: 1.3em; // change checkbox size
            border-radius: 0.125em;
            display: inline-block;
            border: var(--borderWidth) solid var(--borderColor);
            width: 0.9em;
            height: 0.9em;
            position: relative;
        }
        .checkbox-wrapper input[type=checkbox]:before,
        .checkbox-wrapper input[type=checkbox]:after {
            content: "";
            position: absolute;
            background: var(--checkedColor);
            width: calc(var(--borderWidth) * 1.8);
            height: var(--borderWidth);
            top: 45%;
            left: 10%;
            transform-origin: left center;
        }
        .checkbox-wrapper input[type=checkbox]:before {
            transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(0);
            transition: transform 100ms ease-in 100ms;
        }
        .checkbox-wrapper input[type=checkbox]:after {
            width: calc(var(--borderWidth) * 5);
            transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(0);
            transform-origin: left center;
            transition: transform 100ms ease-in;
        }
        .checkbox-wrapper input[type=checkbox]:checked:before {
            transform: rotate(45deg) translate(calc(var(--borderWidth) / -4.8), calc(var(--borderWidth) / -2.4)) scaleX(1.0);
            transition: transform 100ms ease-in;
        }
        .checkbox-wrapper input[type=checkbox]:checked:after {
            width: calc(var(--borderWidth) * 4);
            transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(1);
            transition: transform 100ms ease-out 100ms;
        }
        .checkbox-wrapper input[type=checkbox]:focus {
            // outline: calc(var(--borderWidth) / 8) solid rgba(0, 0, 0, 0.25);
        }
    }
    &._dropdown {
        width: 16em;
        font-size: 14px;
        font-family: $primary_font;
        
        .form-label {
            color: #333333 !important;
        }

        .dropdown {
            button {
                display: flex;
                justify-content: end;
                align-items: center;
                width: 100%;
                color: #707070;
                font-size: 12px;
                font-family: $primary_font; 
                border-radius: 5px;
                padding: 0.6em 1em;
                text-transform: capitalize;
                text-align: left;

                background: transparent;
                box-shadow: none !important;
                border: 2px solid #dfdfdf;
            }

            button:after {
                margin-left: auto;
                border-top: 0.4em solid #acacac; // change dropdown toggle
            }

            .dropdown-menu {
                width: 100%;

                li {
                    color: #707070;
                    font-size: 12px;
                    font-family: $primary_font; 
                    padding: 0.5em 1em;
                }
                
                li:hover {
                    color: #333333;
                    cursor: pointer;
                    background-color: #fafafa;
                }
            }
        }
    }
    &._radio {
        font-size: 14px;
        font-family: $primary_font;
        
        .form-label {
            color: #333333 !important;
        }

        .radio-buttons {
            display: flex;
            flex-direction: column;
            color: white;
        }
        
        .radio-button {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            cursor: pointer;
        }
        
        .radio-button input[type="radio"] {
            display: none;
        }
        
        .radio-circle {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #cacaca;
            position: relative;
            margin-right: 10px;
        }
        
        .radio-circle::before {
            content: "";
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #cacaca;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: all 0.2s ease-in-out;
        }
        
        .radio-button input[type="radio"]:checked + .radio-circle::before {
            transform: translate(-50%, -50%) scale(1);
        }
        
        .radio-button input[type="radio"]:checked + .radio-circle::before {
            background-color: #acacac;
        }
        
        .radio-label {
            font-size: 16px;
            font-weight: bold;
        }
        
        .radio-button:hover .radio-circle {
            border-color: #acacac;
        }
        
        .radio-button:hover input[type="radio"]:checked + .radio-circle::before {
            background-color: rgb(133, 133, 133);
        }
    }
    &._errors{
        margin: 0.5em 0em 0.5em 1em;
        margin-left: 1em;
        font-size: 12px;
        color: #ff4444;
        font-family: 'poppins-semibold';
    }
    &._snow-effect{
        @function random_range($min, $max) {
            $rand: random();
            $random_range: $min + floor($rand * (($max - $min) + 1));
            @return $random_range;
          }
          
        .snow {
            $total: 200;
            position: absolute;
            width: 12px;
            height: 12px;
            background: white;
            border-radius: 50%;
            z-index: -1;

            @media (max-width: 991px) {
                width: 10px;
                height: 10px;
            }

            @media (max-width: 550px) {
                width: 8px;
                height: 8px;
            }
        
        @for $i from 1 through $total {
            $random-x: random(1000000) * 0.0001vw;
            $random-offset: random_range(-100000, 100000) * 0.0001vw;
            $random-x-end: $random-x + $random-offset;
            $random-x-end-yoyo: $random-x + ($random-offset / 2);
            $random-yoyo-time: random_range(30000, 80000) / 100000;
            $random-yoyo-y: $random-yoyo-time * 100vh;
            $random-scale: random(10000) * 0.0001;
            $fall-duration: random_range(10, 30) * 1s;
            $fall-delay: random(30) * -1s;
        
            &:nth-child(#{$i}) {
            opacity: random(10000) * 0.0001;
            transform: translate($random-x, -10px) scale($random-scale);
            animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
            }
        
            @keyframes fall-#{$i} {
            #{percentage($random-yoyo-time)} {
                transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
            }
        
            to {
                transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
            }
            }
        }
        }
    }
}

//
.common {
    &._icon-button {
        padding: 25px 10px 10px 22px;
        min-height: 60px;

        div {
            width: 40px;
            height: 40px;
            background-color: #FDFDFD4D;
            border-radius: 25px;
            backdrop-filter: blur(47px)
        }
        
        &.--blue {
            div {
                background-color: #113b63db;
            }
        }
    }
    &._page-header {
        label {
            display: flex;
            align-items: center;

            color: $secondary_text_color;
            font-size: 30px;
            font-family: 'cabin-semibold';
        }

        div {
            color: $primary_text_color;
            font-size: 14px;
            font-family: 'cabin-medium';
        }
    }
    &._symbol-line {
        margin: 1.8em 0em;

        .line {
            opacity: 0.25; 
            border-bottom: 1px #9EBCD8 solid;
            width: 100%;

            display: flex;
            align-items: center;
            
            label {
                position: absolute;
                font-size: 16px;
                color: #9EBCD8;
                right: -1%;
            }
        }
        
    }
}

.MuiMenu-list {
    max-height: 50em !important;
    overflow-y: scroll;

    @media (max-width: 550px) {
        max-height: 34.8em !important;
    }

    .MuiMenuItem-root {
        min-height: 45px !important;
        color: $primary_background_color;
        font-size: 14px;
        font-family: 'poppins-medium';

        // @media (max-width: 550px) {
        //     min-height: 40px !important;
        // }
    }
}

.MuiMenu-list::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    background-color: #F5F5F5;
}

.MuiMenu-list::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #9ebcd8c2;
}

.flex {
    display: flex;
}

.items-start {
    align-items: start;
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: end;
}

.items-baseline {
    align-items: baseline;
}

.justify-start {
    justify-content: start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: end;
}

.justify-between {
    justify-content: space-between;
}

.relative {
    position: relative;
}

.pointer{
    cursor: pointer;
}

.disabled{
    cursor: not-allowed;
}

.hide{
    display: none;
}

.hidden{
    overflow: hidden;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
